<template>
  <b-card>
    <b-card-body v-if="isStaff">
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <h3 class="m-0 font-weight-600">
            {{ $t('votes.results-title') }}
          </h3>
        </div>
      </div>

      <div class="ml-2 mb-3">
        <p>
          {{ $t('votes.select-vote') }}
        </p>
        <b-row>
          <b-col>
            <v-select
              v-model="optionForm"
              class="select-control p-0"
              :clearable="false"
              :reduce="(a) => a.key"
              :options="items.groups"
              label="createdAt"
              :placeholder="$t('votes.voting-session')"
              @input="selectQuestions"
            />
          </b-col>
        </b-row>
        <div class="mt-1 d-flex">
          <div class="round legend online mr-50" />
          <small class="text-secondary">{{ $t('votes.online-votes') }}</small>
          <div class="round legend onsite ml-4 mr-50" />
          <small class="text-secondary">{{ $t('votes.in-person-votes') }} </small>
        </div>
        <div v-if="isLoading" class="d-flex my-5 justify-content-center align-items-center">
          <b-spinner variant="primary my-5 mr-1" />
          <p class="text-primary h4 title my-5 mb-0">
            Loading
          </p>
        </div>
        <div v-if="!isLoading">
          <div v-for="(answer, key, index) in answers" :key="index" class="mt-4 border-top">
            <div class="mt-3">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div
                    class="rounded-circle overflow-hidden d-flex align-items-center justify-content-center number background-light-color"
                  >
                    <h4 class=" m-0 text-primary">
                      {{ index + 1 }}
                    </h4>
                  </div>
                  <h3 class="ml-1 mb-0">
                    {{ translate(answer.name) }}
                  </h3>
                </div>
                <b-button v-b-modal.add-votes variant="outline-primary" @click="selectAnswer(answer, key)">
                  + {{ $t('votes.add-votes') }}
                </b-button>
              </div>
              <div class="d-flex">
                <div class="line-container d-flex align-items-center justify-content-center mt-75">
                  <div class="line h-100 background-light-color" />
                </div>
                <div class="w-100 ml-1">
                  <div v-for="(option, optionIndex) in answer.options" :key="optionIndex">
                    <h5 class="mt-2">
                      {{ translate(option) }}
                      <!--             <b-icon-trophy
                        v-if="winnerOptions.length > 0 && optionIndex === winnerOptions[index]"
                        font-scale="1"
                        class="ml-50"
                        variant="primary"
                      /> -->
                    </h5>
                    <div>
                      <b-progress
                        height="1.3rem"
                        :max="answer.totalVotes"
                        :precision="1"
                        show-value
                      >
                        <b-progress-bar
                          :value="option.total - option.addedVotes"
                          :label="`${(((option.total - option.addedVotes) / answer.totalVotes) * 100).toFixed(2)}%`"
                          variant="primary"
                        />
                        <b-progress-bar
                          :value="option.addedVotes"
                          :label="`${((option.addedVotes / answer.totalVotes) * 100).toFixed(2)}%`"
                          variant="secondary"
                        />
                      </b-progress>
                    </div>
                    <p class="mb-2">
                      {{ $t('votes.total') }}: {{ option.total }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<h3 class="text-center">
          Han votado 23/24 personas
        </h3>-->
      </div>
      <b-modal
        id="add-votes"
        :title="$t('votes.add-votes-title')"
        hide-footer
        centered
        scrollable
      >
        <div v-if="Object.keys(currentAnswer).length > 0">
          <h6 class="my-1">
            {{ translate(currentAnswer.name) }}
          </h6>
          <b-form>
            <b-form-group v-for="(option, index) of currentAnswer.options" :key="index" class="mb-2">
              <label :for="translate(option)">{{ translate(option) }}</label>
              <b-form-input
                :id="translate(option)"
                v-model="addVote[index]"
                step="1"
                :placeholder="$t('votes.add-votes-placeholder')"
                type="number"
                aria-describedby="total"
              />
              <b-form-text id="total">
                Total: {{ totalVotes(option.total, 0) }}
              </b-form-text>
            </b-form-group>
            <b-button
              variant="primary"
              class="float-right"
              :disabled="isSending"
              @click="addVotes"
            >
              <span
                v-if="isSending"
                class="d-flex "
              ><b-spinner
                 class="mr-1"
                 small
                 variant="white"
                 label="Loading..."
               />
                {{ $t('form.actions.saving') }}
              </span>
              <span v-else>{{ $t('form.actions.save') }}</span>
            </b-button>
          </b-form>
        </div>
      </b-modal>
    </b-card-body>
  </b-card>
</template>

<script>
import FaqAccordion from '@/views/apps/faqs/components/FaqAccordion.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import EventsPlaceholder from '@/assets/images/placeholders/light/vote-time.svg';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import CreateVotesModal from '@/@core/components/modal/CreateVotesModal.vue';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import EditVotesModal from '@/@core/components/modal/EditVotesModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import vSelect from 'vue-select';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'VotesResults',
  components: {
    FaqAccordion,
    FormTranslationTable,
    ListContainerWidget,
    WidgetActions,
    CreateVotesModal,
    EditVotesModal,
    UnshareModal,
    vSelect,
    VueApexCharts,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
  },
  props: {
    actual: {
      type: Object,
      default: () => {},
    },
    finished: {
      type: Boolean,
      default: false,
    },
    actualNum: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      searchInput: '',
      results: [],
      keyup: null,
      isLoading: false,
      isSending: false,
      open: false,
      itemEdit: { name: {}, options: {} },
      isUnshareModalVisible: false,
      itemDelete: null,
      optionsEdit: {},
      question: {},
      addVote: {},
      questionsToShow: [],
      votesAnswers: [],
      voted: false,
      formkey: null,
      options: [{}, {}],
      form: [],
      optionForm: null,
      optionForm2: null,
      selectedQuestion: [],
      max: 23,
      totalOptions: [],
      winnerOptions: [],
      nameLeng: '',
      currentAnswer: {},
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale?.currentLocale;
    },
    entityPlaceholder() {
      return EventsPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    itemsData() {
      return this.$store.getters.votes;
    },
    formAnswers() {
      return this.$store.getters.votesAnswers?.unpaginated;
    },
    answers() {
      const data = {};
      for (const question of this.formAnswers) {
        if (data[this.translate(question.name)] == null) {
          data[this.translate(question.name)] = question;
          data[this.translate(question.name)].totalVotes = 1;
        } else {
          data[this.translate(question.name)].totalVotes++;
        }
        for (const row in question.options) {
          data[this.translate(question.name)].options[row].total = 0;
          data[this.translate(question.name)].options[row].addedVotes = 0;
        }
      }
      for (const row of this.formAnswers) {
        for (const options in data[this.translate(row.name)].options) {
          if (Object.values(data[this.translate(row.name)].options[options]).includes(row.response.toString())) {
            data[this.translate(row.name)].options[options].total++;
            if (row.onsite) {
              data[this.translate(row.name)].options[options].addedVotes++;
            }
          }
        }
      }
      return data;
    },

    items() {
      const itemsToIterate = this.searchInput ? this.results : this.itemsData.unpaginated;
      const item = itemsToIterate.find((element) => element.key === this.$route.params.id);
      if (item?.groups != null) {
        for (const row in item.groups) {
          const date = new Date(item.groups[row].createdAt);
          item.groups[row].createdAt = date.toLocaleString(this.currentLocale);
        }
      }
      return item || {};
    },
  },
  watch: {
    answers() {
      this.isLoading = true;
      this.winnerOptions;
      this.isLoading = false;
    },
    winnerOption() {
      this.isLoading = true;
      this.isLoading = false;
    },
  },

  async created() {
    await this.fetchData();
    this.nameLeng = this.items.name[this.currentLocale]
      ? this.items.name[this.currentLocale]
      : Object.values(this.items.name)[0];
    this.updateBreadcrumbs();
    this.winnerOption();
  },

  methods: {
    addVotes() {
      try {
        const arrayToSend = [];
        for (const row of Object.keys(this.addVote)) {
          const tempOb = {};
          this.answers[this.currentAnswer.index].options[row].total += parseInt(this.addVote[row]);
          this.answers[this.currentAnswer.index].options[row].addedVotes += parseInt(this.addVote[row]);
          this.answers[this.currentAnswer.index].totalVotes += parseInt(this.addVote[row]);
          tempOb[this.translate(this.answers[this.currentAnswer.index].options[row])] = this.addVote[row];
          arrayToSend.push(tempOb);
        }
        this.isLoading = true;
        this.isLoading = false;
        this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'forms/addVotes',
            requestConfig: {
              questionKey: this.currentAnswer.key,
              votes: arrayToSend,
              groupUUID: this.optionForm,
            },
          },
        });
        this.notifySuccess(this.$t('votes.edit-success'));
        this.winnerOption();
        this.$bvModal.hide('add-votes');
        this.addVote = {};
      } catch {
        this.notifyError(this.$t('votes.edit-error'));
      }
    },
    winnerOption() {
      this.winnerOptions = [];
      const answers = Object.values(this.answers);
      for (const answer of answers) {
        const newArray = [];
        for (const option of answer.options) {
          newArray.push(option.total);
        }
        const max = Math.max(...newArray);
        const indexWinnerOption = newArray.indexOf(max);
        this.winnerOptions.push(indexWinnerOption);
      }
    },
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    async selectQuestions() {
      this.fetchAnswers();
    },
    selectAnswer(answer, index) {
      this.currentAnswer = { ...answer, index };
      this.newVotes = this.currentAnswer.options?.addedVotes;
    },
    totalVotes(onlineVotes, presentVotes) {
      const totalVotes = +onlineVotes + +presentVotes;
      return totalVotes;
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    async fetchData(force = true) {
      await this.$store.dispatch('getItems', {
        itemType: 'forms',
        page: 1,
        forceAPICall: force,
        customName: 'votes',
        requestConfig: {
          type: 'vote',
          orderByDate: -1,
        },
      });
    },
    async fetchAnswers() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'formAnswers',
        forceAPICall: true,
        customName: 'votesAnswers',
        requestConfig: {
          groupUUID: this.optionForm,
        },
      });
      this.isLoading = false;
    },
    getIfAnswered() {
      this.$socket.emit('getIfAnswered', {
        communitySlug: this.$store.getters.currentCollective.slug,
        memberKey: this.$store.getters.loggedMember.key,
      });
    },
    voteAnswer(response) {
      this.$socket.emit('answerQuestion', {
        communitySlug: this.$store.getters.currentCollective.slug,
        questionKey: this.item.key,
        memberKey: this.$store.getters.loggedMember.key,
        response,
      });
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'votes.title',
          to: { name: 'votes' },
        },
        {
          text: `${this.nameLeng}`,
          active: true,
        },
        {
          text: 'votes.results-title',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';
.modal-logo {
  max-width: 16rem;
  min-width: 10rem;
}
.card-standard {
  width: 60% !important;
}
.option {
  overflow: hidden;
}
.option :hover {
  background-color: lighten($primary,40%);
}
.border-top {
  border-top: 1px solid #ebe9f1 !important;
}
.border-bottom {
  border-bottom: 2px solid #ebe9f1 !important;
}
.bg-secondary {
  background-color: #7fd8be !important;
}
.legend {
  height: 15px;
  width: 40px;
}
.online {
  background-color: $primary;
}
.onsite {
  background-color: #7fd8be;
}
.number {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}
.line-container {
  min-width: 2.5rem;
}
.line {
  min-width: 0.2rem;
}

//#7fd8be #27aab899
</style>
